<template>
  <NModal
    v-model:show="modalShow"
    :mask-closable="false"
  >
    <NCard
      style="width: 600px;"
      title="正在上传文件"
      size="small"
      :segmented="{ content: 'hard' }"
    >
      <UploadProgress
        v-for="(item, index) in upFileList"
        :key="index"
        :percentage="item.upPercentage"
        :file-name="item.name"
      />
    </NCard>
  </NModal>
</template>

<script setup>
  import { ref } from 'vue';

  import UploadProgress from './components/UploadProgress.vue';

  import { resStatusEnum } from '@/enumerators/http.js';
  import { createOSSClient, multipartUpload } from './oss-client.js';
  import { uploadVideo } from './video-uploader.js';
  import { correctURL } from '@/utils/url.js';

  import fileTypeEnum from '@/enumerators/file-type.js';

  const emit = defineEmits([
    'upload-error',
    'all-file-success'
  ]);
  const props = defineProps({
    silentUpload: {
      type: Boolean,
      default: false
    }
  });

  const { SUCCESS } = resStatusEnum;

  const modalShow = ref(false);
  const openModal = () => {
    if (props.silentUpload) {
      return;
    }
    modalShow.value = true;
  };
  const closeModal = () => {
    modalShow.value = false;

    fileListStore.value.splice(0);
  };

  const fileListStore = ref([]); // 接收的文件列表，二维数组

  /*
    上传的文件列表，一维数组
  */
  const upFileList = ref([]);
  let upVideoFileList = []; // 音视频
  let upImgFileList = []; // 文件
  const succeedUniques = {
    // hash: true
  };
  
  const updateUpFileList = (fileList) => {
    fileListStore.value = fileList;

    const tempUpFileList = [];
    fileListStore.value.forEach((fileSetItem) => {
      fileSetItem.forEach((fileItem) => {
        if (!succeedUniques[fileItem.fileUniqueStr] && !fileItem.saved) {
          tempUpFileList.push(fileItem);
        }
      });
    });
    upFileList.value = tempUpFileList;
    upImgFileList = upFileList.value.filter(item => {
      if (item.generalFileUpload) {
        return true;
      } else {
        return item.fileType !== fileTypeEnum.VIDEO;
      }
    });
    upVideoFileList = upFileList.value.filter(item => {
      if (item.generalFileUpload) {
        return false;
      } else {
        return item.fileType === fileTypeEnum.VIDEO;
      }
    });
  };

  const startOSSUpload = ({ OSSClient, folder }) => {
    upImgFileList.forEach(item => {
      const {
        file,
        fileUniqueStr,
        ext
      } = item;
      multipartUpload({
        OSSClient,
        file,
        filePath: `${ folder }/${ fileUniqueStr }${ ext }`,
        progress: (progress) => {
          item.upPercentage = Math.round(progress * 100);
        }
      }).then(res => {
        // item.fileUrl = res.url;
        try {
          // item.fileUrl = res.res.requestUrls[0].split('?')[0];
          item.fileUrl = correctURL('file', res.name);
        } catch (e) {
          console.log(e);
        }
        item.upPercentage = 100;
        succeedUniques[fileUniqueStr] = true;
      }).catch(err => {
        item.upPercentage = -1;
      }).finally(() => {
        item.upComplete = true;
        handleFileComplete();
      });
    });
  };
  const startVideoUpload = () => {
    upVideoFileList.forEach(item => {
      const {
        name,
        file,
        fileUniqueStr
      } = item;
      const onUploadSucceed = uploadInfo => {
        /*
        const fileUrlArr = uploadInfo.endpoint.split('://');
        fileUrlArr.splice(1, 0, '://', uploadInfo.bucket, '.');
        item.fileUrl = `${ fileUrlArr.join('') }/${ uploadInfo.object }`;
        */
        item.fileUrl = correctURL('video', uploadInfo.object);
        item.videoId = uploadInfo.videoId;
        item.upPercentage = 100;
        item.upComplete = true;
        succeedUniques[fileUniqueStr] = true;
        handleFileComplete();
      };
      const onUploadFailed = (uploadInfo, code, message) => {
        item.upPercentage = -1;
        item.upComplete = true;
        handleFileComplete();
      };
      const onUploadProgress = (uploadInfo, totalSize, loadedPercent) => {
        item.upPercentage = Math.ceil(loadedPercent * 100);
      };
      uploadVideo({
        name,
        file,
        onUploadSucceed,
        onUploadFailed,
        onUploadProgress
      });
    });
  };
  const startUpload = fileList => {
    if (Array.isArray(fileList) && fileList.length > 0) {
      updateUpFileList(fileList);

      if (upImgFileList.length > 0 || upVideoFileList.length > 0) {
        openModal();

        // 上传图片、PDF
        if (upImgFileList.length > 0) {
          createOSSClient().then(({ OSSClient, folder }) => {
            startOSSUpload({ OSSClient, folder });
          });
        }

        // 上传 mp4、mp3
        if (upVideoFileList.length > 0) {
          startVideoUpload();
        }
      } else {
        emit('all-file-success', fileListStore.value);
      }
    } else {
      emit('all-file-success', []);
    }
  };

  const isAllFileComplete = () => {
    for (let i = 0, l = upFileList.value.length; i < l; i++) {
      if (!upFileList.value[i].upComplete) {
        return false;
      }
    }
    return true;
  };
  const isAllFileSuccess = () => {
    for (let i = 0, l = upFileList.value.length; i < l; i++) {
      if (upFileList.value[i].upPercentage === -1) {
        return false;
      }
    }
    return true;
  };

  const handleFileComplete = () => {
    if (isAllFileComplete()) {
      if (isAllFileSuccess()) {
        emit('all-file-success', fileListStore.value);
      } else {
        emit('upload-error');
      }
      closeModal();      
    }
  };

  defineExpose({
    startUpload,
    closeModal
  });
</script>