<template>
  <div class="up-progress">
    <div class="progress-title">
      <span :title="fileName">{{ fileName }}</span>
      <span class="up-status">{{ percentage < 100 ? '正在上传' : '上传成功' }}</span>
    </div>
    <NProgress
      :percentage="percentage"
      :height="10"
      :color="color"
      :indicator-text-color="color"
      :processing="!isCompleted"
    />
  </div>
</template>

<script setup>
  import { computed } from 'vue';

  import themeSettings from '@/settings/theme-settings.js';

  const props = defineProps({
    fileName: {
      type: String,
      default: '文件'
    },
    percentage: {
      type: Number,
      default: 0
    }
  });

  const isCompleted = computed(() => {
    return props.percentage >= 100;
  });
  const color = computed(() => {
    return isCompleted.value ?
      themeSettings.successColor :
      themeSettings.appThemeColor;
  });
</script>

<style lang="less" scoped>
  @import "~@/styles/variables.less";

  .up-progress {
    margin: 15px 0;
    padding: 0 20px;
  }
  .progress-title {
    position: relative;
    margin-bottom: 2px;
    padding-right: 140px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
  }
  .up-status {
    position: absolute;
    right: 50px;
    color: #999;
    // color: @theme-color;

    &.success {
      color: @success-color;
    }
  }
</style>