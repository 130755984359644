<template>
  <div>
    <NCard
    size="small"
    :bordered="false"
    :segmented="{ content: 'hard' }"
    :title="isEdit ? '编辑实训作业' : '新增实训作业'"
  >
    <NForm
      ref="formRefS"
      label-width="95px"
      label-placement="left"
      require-mark-placement="left"
      :model="formValue"
      :rules="formRules"
    >
      <NFormItem label="题干：" path="title">
        <NInput
          style="width: 550px;"
          :autosize="{ minRows: 6, maxRows: 6 }"
          type="textarea"
          maxlength="2000"
          show-count
          clearable
          v-model:value="formValue.title"
        />
      </NFormItem>
      <NFormItem label="作业文件：" path="file_url">
        <AddLocalFile
          ref="addLocalFileRef"
          :max-num="maxFileNum"
          :max-bytes-size="300 * 1024 * 1024"
          accept='.rar,.zip,.doc,.docx,.pdf'
          accept-size-txt="文件大小不超过 300MB"
          @file-list-change="handleUpFileChange"
          :init-list="initUploadList"
        />
      </NFormItem>
      <NFormItem label="习题解析：">
        <NInput
          style="width: 550px;"
          :autosize="{ minRows: 6, maxRows: 6 }"
          type="textarea"
          maxlength="2000"
          show-count
          clearable
          v-model:value="formValue.ques_analys"
        />
      </NFormItem>
      <NFormItem label="分值：" path="score">
        <NInputNumber
          style="width: 145px;"
          :min="0"
          :max="100"
          :step="0.5"
          v-model:value="formValue.score"
          @focus="handleScoreFocus"
          @blur="handleScoreBlur"
        />
      </NFormItem>
      <NFormItem  label="标签：" path="labels">
        <ItemAddLabel
          ref="itemAddLabelRef"
          :label-type="resourceEnum.EXERCISES_RESOURCE_TYPE"
          @list-update="handleLabelChange"
        />
      </NFormItem>
      <NFormItem style="margin-top: 20px;">
      <NSpace>
        <NButton @click="handleBack">返回</NButton>
        <NButton type="primary" @click="DocumentSubmission">保存</NButton>
      </NSpace>
      </NFormItem>
    </NForm>
  </NCard>

  <BeforeSubmitUpload
      ref="beforeSubmitUploadRef"
      @all-file-success="submitFormValues"
      @upload-error="handleUploadError"
    />

    <PageLoading :loading="loading" />
  </div>
</template>

<script setup>

  import { useRoute, useRouter } from 'vue-router';
  import { useMessage } from 'naive-ui';

  import PageLoading from '@/components/PageLoading/index.vue';

  import { resStatusEnum } from '@/enumerators/http.js';

  import { createHomework, editHomework, getExerciseDetail } from '@/api/exercises.js';

  import { ref, reactive } from 'vue';
  import { toRaw } from "@vue/reactivity";

  import AddLocalFile from '@/components/AddLocalFile/AddLocalFile.vue';
  import BeforeSubmitUpload from '@/components/BeforeSubmitUpload/BeforeSubmitUpload.vue';
  import ItemAddLabel from '@/components/ItemAddLabel/ItemAddLabel.vue';

  import { questionTypeEnum } from '@/enumerators/question-type-map.js';

  import { resourceEnum } from '@/enumerators/resource-types-map.js';

  const { SUCCESS } = resStatusEnum;
  const route = useRoute();
  const router = useRouter();
  const message = useMessage();
  const loading = ref(false);

  const teachingId = route.query.id;
  const isEdit = !!teachingId;

  // const getDefaultFormValue = 
  const formRefS = ref(null);
  const formValue = reactive({
      'type':6,
      'type_status':2,// 2:实训作业
      'score':1,      // 分值
      'ques_analys':'',// 解析
      'title':'',
      'file_url':'',
      "upload":[],
      'label':[],
      'labels':[]
  });

  const uploadParamsMap = {
    fileUrl: 'file_url',
    name: 'file_name',
    size: 'file_size',
    ext: 'extension',
    fileType: 'file_type'
  };

  const handleBack = () => {
    router.push('list');
  };

  const beforeSubmitUploadRef = ref(null);

  function DocumentSubmission() {
    const fileList = [
      addLocalFileRef.value.getFileList()
    ];
    beforeSubmitUploadRef.value.startUpload(fileList);
  }

  const submitFormValues = fileList => {
    let reqFn;
    let successMsg = '';
    if (isEdit) {
      reqFn = editHomework;
      successMsg = '编辑作业成功';
    } else {
      reqFn = createHomework;
      successMsg = '新增作业成功';
    }
    
    Object.keys(formValue).forEach(key => {
      switch (key) {
        case 'id':
          formValue[key] = teachingId;
          break;
        case 'upload':
          formValue.upload = fileList[0].filter(item => !item.savedId).map(item => {
          const tempItem = {};
          Object.keys(uploadParamsMap).forEach(key => {
            tempItem[uploadParamsMap[key]] = item[key];
          });
          return tempItem;
          });
          // 当文件没改变时
          if(formValue.upload.length==0){
          const tempItem = {
            'file_url':initUploadList.value[0].fileUrl,
            'file_name':initUploadList.value[0].name,
            'file_size':initUploadList.value[0].file.size,
            'extension':initUploadList.value[0].extension,
            'file_type':1,
            'id':initUploadList.value[0].id,
            'savedId':initUploadList.value[0].id
          };
          formValue.upload = []
          formValue.upload.push(tempItem);
          }
          break;
        case 'labels':
          formValue.label = []
          formValue.labels.filter(item => formValue.label.push(item.id));
          break;
        default:
          formValue[key] = formValue[key];
      }
      // console.log('formValue',formValue);
    });

    let ValueTransmission = {
      "Exercises[id]":teachingId,
      "Exercises[title]":formValue.title,
      "Exercises[type]":formValue.type,
      "Exercises[score]":formValue.score,
      "Exercises[label]":formValue.label,
      "Exercises[upload]":formValue.upload,
      "Exercises[type_status]":2,
      "Exercises[ques_analys]":formValue.ques_analys
    }

    formRefS.value.validate().then( errMsg => {
    if (!errMsg){
      const reqData = {};
      loading.value = true;
      reqFn(ValueTransmission).then(res => {
        if (res.code === SUCCESS) {
          message.success(successMsg);
          setTimeout(() => {
            handleBack();
          }, 120);
        }
      }).catch(err => {}).finally(() => {
        loading.value = false;
      });
    }
    }, rule => {});
  };
  
  const handleUploadError = () => {
    console.log('有文件上传失败，返回');
  };

  const props = defineProps({
    exerciseId: {
      type: String,
      default: null
    }
  });

  const emit = defineEmits(['back-click', 'save-click']);
  
  const addLocalFileRef = ref(null);

  const initUploadList = ref([]);

  const teachingType = route.query.type;

  const maxFileNum = (() => {
    return 1;
  })();

  const formRules = {
    title: {
      required: true,
      message: '必填',
      trigger: 'blur'
    },
    score: {
      type: 'number',
      required: true,
      message: '必填',
      trigger: ['blur', 'change']
    },
    file_url: {
      key: 'file_url',
      required: true,
      validator: (rule, value) => {
        if (addLocalFileRef.value.getFileList().length > 0) {
          return true;
        } else {
          return new Error('请添加文件');
        }
      }
    },
    labels: {
      key: 'labels',
      required: true,
      validator: () => {
        if (selectedLabels.value.length > 0) {
          return true;
        } else {
          return new Error('请添加标签');
        }
      }
    },
  };

  const handleUpFileChange = () => {
    formRefS.value.validate(errors => {
    }, rule => {
      return rule.key === 'file_url';
    });
  };

  const handleScoreFocus = (e) => {
    setTimeout(() => {
      e.target.select();
    }, 150);
  };
  const handleScoreBlur = () => {
    let value = formValue.score;
    if (value) {
      value = Math.round(value * 10) / 10;
      value < 0.1 && (value = 0.1);
      formValue.score = value;
    } else {
      formValue.score = null;
    }
  };

  const selectedLabels = ref([]);

  const handleLabelChange = selectedLabelsArr => {
    selectedLabels.value = selectedLabelsArr;
    formValue.labels = selectedLabels.value
    triggerLabelValidator();
  };

  const triggerLabelValidator = () => {
    formRefS.value.validate(
      errors => {},
      rule => rule.key === 'label'
    );
  };

  const itemAddLabelRef = ref(null);

  if (isEdit) {
    loading.value = true;
    getExerciseDetail({
      'Exercises[id]': teachingId
    }).then(res => {
      if (res.code === SUCCESS) {
        let resData = res.data;
        if (resData.upload_list[0]) {
          initUploadList.value.push({
            id: resData.upload_list[0].id,
            savedId: resData.upload_list[0].id,
            name: resData.upload_list[0].file_name,
            status: 'pending',
            file: {
              size: Number(resData.upload_list[0].file_size)
            },
            saved: true,
            fileUrl: resData.upload_list[0].file_url,
            extension:resData.upload_list[0].extension
          })
          formValue.file_url = resData.upload_list[0].file_url
        }
        Object.keys(formValue).forEach(key => {
          switch (key) {
            case 'id':
              formValue[key] = teachingId;
              break;
            case 'labels':
              if (Array.isArray(resData.label_list)) {
                // ID集合
                let label = []
                resData.label_list.forEach(item=>{
                  label.push(item.label_id)
                })
                formValue.label = label;
                // 所选数据集合
                let labels = []
                resData.label_list.forEach(item=>{
                  let itemObj = {
                    id:item.label_id,
                    file_num:item.file_num,
                    title:item.title
                  }
                  labels.push(itemObj)
                })
                formValue.labels = labels;
                selectedLabels.value = labels;
                itemAddLabelRef.value.setSelectedLabels(labels);
              }
              break;
            case 'score':
              formValue[key] = Number(resData[key]);
              break;
            default:
              formValue[key] = resData[key];
          }
        });
      }
    }).catch(err => {}).finally(() => {
      loading.value = false;
    });
  }
</script>