import request from '@/utils/request.js';
import { formDataUploadFile } from '@/utils/formdata-upload-file.js';
import { reqMethodEnum } from '@/enumerators/http.js'
const { GET, POST } = reqMethodEnum;

export function getExerciseList(data) {
  return request({
    url: '/backend/web/exercises/index',
    method: GET,
    params: data
  });
}

export function PostExerciseList(data) {
  return request({
    url: '/backend/web/exercises/index',
    method: POST,
    data
  });
}

export function delExercise(data) {
  return request({
    url: '/backend/web/exercises/delete',
    method: POST,
    data
  });
}

export function batDelExercise(data) {
  return request({
    url: '/backend/web/exercises/batch-delete',
    method: POST,
    data
  });
}

export function batExportExercise(data) {
  return request({
    url: '/backend/web/exercises/batch-export-exercises',
    method: GET,
    params: data
  });
}

export function distributeExercise(data) {
  return request({
    url: '/backend/web/exercises/distribute',
    method: POST,
    data
  });
}

export function createExercise(data) {
  return request({
    url: '/backend/web/exercises/create',
    method: POST,
    data
  });
}

// 习题新增
export function createHomework(data) {
  return request({
    url: '/backend/web/exercises/create',
    method: POST,
    data
  });
}

// 习题编辑
export function editHomework(data) {
  return request({
    url: '/backend/web/exercises/update',
    method: POST,
    data
  });
}

export function getExerciseDetail(data) {
  return request({
    url: '/backend/web/exercises/detail',
    method: POST,
    data
  });
}

export function getExerciseDetails(data) {
  return request({
    url: '/backend/web/material/exercises-detail',
    method: POST,
    data
  });
}

export function editExercise(data) {
  return request({
    url: '/backend/web/exercises/update',
    method: POST,
    data
  });
}

export function copyExercise(data) {
  return request({
    url: '/backend/web/exercises/copy',
    method: POST,
    data
  });
}

export function batImportExercise(data) {
  return formDataUploadFile(
    '/backend/web/exercises/batch-import-exercises',
    data
  );
}