import request from '@/utils/request.js';
import { reqMethodEnum } from '@/enumerators/http.js'
const { GET, POST } = reqMethodEnum;

export function getLabelList(data) {
  return request({
    url: '/backend/web/label/index',
    method: GET,
    params: data
  });
}

export function addLabel(data) {
  return request({
    url: '/backend/web/label/create',
    method: POST,
    data
  });
}

export function editLabel(data) {
  return request({
    url: '/backend/web/label/update',
    method: POST,
    data
  });
}

export function delLabel(data) {
  return request({
    url: '/backend/web/label/delete',
    method: POST,
    data
  });
}