import { getUpVideoAuth } from '@/api/upload.js';
import { resStatusEnum } from '@/enumerators/http.js';
import env from '@/settings/env.js';
const { SUCCESS } = resStatusEnum;

export function uploadVideo({
  name,
  file,
  onUploadSucceed,
  onUploadFailed,
  onUploadProgress
}) {
  const videoUploader = new AliyunUpload.Vod({
    enableUploadProgress: false,
    region: env.aliyunUploadRegion,
    userId: env.aliyunUploadUserId,
    addFileSuccess: () => {
      videoUploader.startUpload();
    },
    onUploadstarted: uploadInfo => {
      getUpVideoAuth({
        title: name,
        filename: name
      }).then(res => {
        if (res.code === SUCCESS) {
          const {
            VideoId,
            UploadAddress,
            UploadAuth
          } = res.data;
          videoUploader.setUploadAuthAndAddress(uploadInfo, UploadAuth, UploadAddress, VideoId);
        } else {
          onUploadFailed();
        }
      }).catch(err => {
        onUploadFailed();
      });
    },
    onUploadSucceed,
    onUploadFailed,
    onUploadProgress,
    onUploadTokenExpired: onUploadFailed
  });
  const paramData = JSON.stringify({
    Vod: {}
  });
  videoUploader.addFile(file, null, null, null, paramData);
}